<template>
  <div>
    <CToaster position="top-right" :autohide="1500">
      <template v-for="(toast, index) in toasts">
        <CToast
            :key="'toast' + toast.text + index"
            :show="true"
            :header="toast.header === '' ? null : toast.header"
        >
          {{ toast.text }}
        </CToast>
      </template>
    </CToaster>
    <CRow>
      <CCol class="col-12 col-xl-4">
        <CCard>
          <CCardHeader>
            <strong>Yeni Yönetici Oluştur</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CInput
                    label="Ad Soyad"
                    placeholder="AdvancerEnglish"
                    v-model="name"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                    type="email"
                    autocomplete="email"
                    label="E-Posta"
                    placeholder="example@advancerenglish.com"
                    required
                    v-model="eMail"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                    :type="passwordInputType"
                    autocomplete="current-password"
                    label="Şifre"
                    placeholder="*************"
                    required
                    minLength="8"
                    description="Şifre minimum 8 karakter olmalıdır"
                    v-model="password"
                >
                  <template #prepend-content>
                    <CIcon name="cil-lock-locked"/>
                  </template>
                  <template #append>
                    <CButton @click="passwordVisibleHandler" color="light">
                      <CIcon name="cil-low-vision"/>
                    </CButton>
                  </template>
                </CInput>
                <c-badge
                    class='col-12 mt-1'
                    v-if='password !== "" && (password.length < 6 || password.length > 64)'
                    color="danger"
                >Şifre Minimum 6, Maksimum 64 Karakter Uzunluğunda Olmalıdır.
                </c-badge>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="mt-3 col-12 col-md-6">
                <CButton @click="createNewAdmin" block color="success">Yeni Yönetici Oluştur</CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-12 col-xl-8">
        <CCard>
          <CCardHeader>
            <strong> Yönetici Listesi </strong>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                class="mb-0 table-outline"
                hover
                :items="tableItems"
                :fields="tableFields"
                head-color="light"
                :items-per-page="10"
                :loading="isDataLoading"
            >
              <template #no-items-view>
                <CRow>
                  <CCol class="col-12 font-3xl text-center">
                    <CIcon name="cil-x"/>
                    Veri Bulunamadı
                  </CCol>
                </CRow>
              </template>
              <td slot="id" slot-scope="{item}">
                <div>{{ item.id }}</div>
                <div class="small text-muted">

                </div>
              </td>
              <td slot="name" slot-scope="{item}">
                <div class="clearfix">
                </div>
                <div>{{ item.name }}</div>
              </td>

              <td slot="eMail" slot-scope="{item}">
                <div class="clearfix">
                </div>
                <div>{{ item.eMail }}</div>
              </td>
              <td slot="manage" slot-scope="{item}">
                <router-link :to="{path: '/yonetici-liste/' + item.id, params: {item}}" tag="button" role="button" block
                             class="btn btn-success ml-1 mb-1">
                  <CIcon name="cil-pencil"/>
                </router-link>
                <CButton @click="deleteAdminAlert(item.id)" color="danger" class="ml-1 mb-1">
                  <CIcon name="cil-x"/>
                </CButton>
              </td>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import {AdministratorAdministration} from "@/services/api-service";

export default {
  name: "Admins",
  components: {},
  mounted() {
    this.loadAdministrators()
  },
  data() {
    return {
      passwordInputType: 'password',
      name: '',
      eMail: '',
      password: '',
      isDataLoading: false,
      toasts: [],
      selected: 'Month',
      tableItems: [],
      tableFields: [
        {key: 'id', label: 'ID'},
        {key: 'name', label: 'İsim'},
        {key: 'eMail', label: 'E-Posta'},
        {key: 'manage', label: 'Yönet'},
      ]
    }
  },
  methods: {
    passwordVisibleHandler() {
      if (this.passwordInputType === "password") {
        this.passwordInputType = "text"
      } else {
        this.passwordInputType = "password"
      }
    },
    color(value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    deleteAdminAlert(id) {
      this.$swal({
        title: 'Emin misiniz?',
        text: "Yaptığınız İşlem Geri Alınamayacaktır!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Sil',
        cancelButtonText: 'Vazgeç'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.tableItems = this.tableItems.filter(r => r.id !== id)
          await AdministratorAdministration.deleteAdministrator(id)
          await this.$swal.fire(
              'Silindi!',
              'İşlem Başarıyla Gerçekleştirildi!',
              'success'
          )
        }
      });
    },
    showToast: function (text = 'Kod Başarıyla Oluşturuldu', header = 'Bilgi') {
      this.toasts.push({text, header});
    },
    async loadAdministrators() {
      this.isDataLoading = true;
      try {
        const result = await AdministratorAdministration.listAdministrators();
        this.tableItems = result.data.administratorList;
      } catch (e) {
        this.toasts.push('Veri Getirilemedi', 'Hata')
      }
      this.isDataLoading = false;
    },


    async createNewAdmin() {
      this.isDataLoading = true;
      try {
        const result = await AdministratorAdministration.createAdministrator(this.name, this.eMail, this.password)
        const newAdmin = {
          id: result.data.id,
          name: this.name,
          eMail: this.eMail,
          password: this.password,
        };
        this.$toast.success("Yeni Yönetici Başarıyla Oluşturuldu!")
        this.tableItems = [newAdmin, ...this.tableItems];
        this.name = '';
        this.eMail = '';
        this.password = '';
      } catch (e) {
      }
      this.isDataLoading = false;
    }
  }
}
</script>
